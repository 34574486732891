import Swal from "sweetalert2";

export const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

export function delay(ms: number) {
    return new Promise<void>((resolve) => {
        setTimeout(resolve, ms);
    });
}

export function handleDefaultError(error: any) {
    switch (error.status) {
        case 401: // Sessione scaduta
        case 419: // Token CSRF non valido
            Toast.fire({ type: "error", title: "Expired session, log in again" });
            // Ritardo di 1500ms per dare all'utente il tempo di leggere
            // il messaggio prima del redirect
            delay(1500).then(() => window.location.reload());
            return true;
    }
    return false;
}

export function handleError(error: any) {
    switch (error.status) {
        case 403:
            Toast.fire({ type: "error", title: "You don't have permission" });
            break;
    }
}
